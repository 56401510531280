<template>
    <v-content>
        <Video />
        <router-view />
    </v-content>
</template>

<script>
export default {
    name: "CoreView",
    components: {
        Video: () => import("@/components/Video"),
    },
    data() {
        return {};
    },
    computed: {
        header() {
            return this.$store.state.header;
        },
        bgmPage() {
            return this.$store.state.bgmPage;
        },
    },
};
</script>

<style>
.v-main__wrap {
    padding-top: 60px;
}
</style>
